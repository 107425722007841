<template>
  <section id="revaluation-list">
    <!-- <div class="content__header">{{ title }}</div> -->
    <div class="b-table__wrapper">
      <documents-header
        page_type="revaluation"
        @date_range="updateDateRange"
      />
      <div class="b-table__content">
        <documents-table-filter
          ref="=doc_table_filter"
          table_name="revaluations-docs"
          placeholder="Поиск"
          @handler_pagination="handlerSearch"
          @clear="clearSearchStr"
        />
        <resizable-table
          style="margin-top: 0 !important"
          :inside_card="false"
          table_name="revaluation-list"
          class="reval-table"
          empty_text="Ничего не найдено"
          :items="items"
          :default_fields="fields"
          :busy="$apollo.queries.RevaluationHeads.loading"
          @row-click="rowClicked"
        >
          <template #head_id>
            <b-checkbox v-model="select_all" />
          </template>

          <template #body_id="{ value, item }">
            <div
              class="b-statuses"
              :class="{ success: item.fixed }"
            ></div>
            <div @click.stop>
              <b-checkbox
                v-model="selected"
                :value="value"
              />
            </div>
          </template>

          <template #body_date="{ item }">
            <div class="b-date text-left">{{ item.number }}</div>
            <div class="b-date date mt-1 table-substr text-left">
              {{ item.date ? formatDate(item.date) : '' }}
            </div>
          </template>

          <template #body_operation>
            <span class="d-flex float-left">Переоценка</span>
          </template>

          <template #body_fixed="{ item }">
            <b-badge :class="{ success: item.fixed }">
              {{ item.fixed ? 'Проведен' : 'Черновик' }}
            </b-badge>
          </template>

          <template #body_sum="{ value }">
            <span class="d-flex float-right"> {{ formatPrice(value) }} ₽ </span>
          </template>

          <template #body_new_sum="{ value }">
            <span class="d-flex float-right"> {{ formatPrice(value) }} ₽ </span>
          </template>

          <template #body_owner="{ item }">
            <span class="d-flex float-left">
              {{ item.owner?.last_name ?? '' }}
              {{ item.owner?.first_name ?? '' }}
            </span>
          </template>
        </resizable-table>
      </div>
    </div>
    <table-navbar
      :items="selected"
      :clear_selected="clearSelected"
      items_name="documents_reval"
      hide_print
      @remove_items="removeHeads"
      @print_price_tags="print_price_tags"
    />
    <!--    TODO remove specs-->

    <pagination

      :total="total"
      table_name="revaluations-docs"
      @change_pagination="handlerPagination"
    />
    <modal-print-price-tags :products="printProducts" />
  </section>
</template>

<script>
  import ModalPrintPriceTags from '@/views/core/modal/ModalPrintPriceTags'
  import DocumentsHeader from '@/views/operational-processes/components/DocumentsHeader'
  import DocumentsTableFilter from '@/views/operational-processes/components/DocumentsTableFilter'
  import ResizableTable from '@/components/ResizableTable'
  import Pagination from '@/views/shared/components/table/Pagination'
  import { mapActions, mapGetters } from 'vuex'
  import { RevaluationHeadModel } from '@/views/service/revaluation/models/revaluation-head.model'
  import TableNavbar from '@/views/shared/components/table/TableNavbar'
  import { ProductModel } from '@/models/product.model'
  export default {
    name: 'Revaluations',

    apollo: {
      RevaluationHeads: {
        query: require('../gql/RevaluationHeads.graphql'),
        deep: true,
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
        variables() {
          return {
            input: {
              branch: this.currentBranch?.__typename === 'BranchClassifModel' ? null : this.currentBranch.id,
              pagination: {
                skip: this.getPagination?.['revaluations-docs']?.skip,
                take: this.getPagination?.['revaluations-docs']?.take,
                search: this.getPagination?.['revaluations-docs']?.search
              }
            },
            group: this.currentBranch?.__typename === 'BranchClassifModel' ? this.currentBranch.id : null
          }
        },
        result({ data }) {
          if (!data?.RevaluationHeads) return
          this.selected = []
          this.items = data?.RevaluationHeads?.documents?.map((el) => new RevaluationHeadModel(el))
          this.total = data?.RevaluationHeads?.total
        }
      }
    },

    components: {
      TableNavbar,
      Pagination,
      ResizableTable,
      DocumentsTableFilter,
      DocumentsHeader,
      ModalPrintPriceTags
    },
    data() {
      return {
        printProducts: [],
        title: this.$route.meta.title,
        pagination: {
          skip: 0,
          take: 20
        },
        select_all: false,
        selected: [],
        fields: [
          { key: 'id', label: '', width: 40, checked: true },
          { key: 'date', label: 'Дата', width: 75, checked: true },
          {
            key: 'sum',
            label: 'Итого в старых ценах, ₽',
            width: 140,
            checked: true
          },
          {
            key: 'new_sum',
            label: 'Итого в новых ценах, ₽',
            width: 140,
            checked: true
          },
          { key: 'operation', label: 'Операция', width: 80, checked: true },
          { key: 'fixed', label: 'Статус', width: 120, checked: true },
          { key: 'owner', label: 'Менеджер', width: 140, checked: true },
          { key: 'comment', label: 'Комментарий', width: 200, checked: true }
        ],
        items: [],
        total: null
      }
    },
    watch: {
      select_all(newVal) {
        if (!newVal) return (this.selected = [])

        this.items.forEach((item) => {
          const id = item.id
          if (!this.selected.includes(id)) this.selected.push(id)
        })
      }
    },
    methods: {
      ...mapActions({
        setPagination: 'settings/set_pagination'
      }),
      clearSelected() {
        this.selected = []
        this.select_all = false
      },
      async print_price_tags(value) {
        const { data } = await this.$apollo.query({
          query: require('../../../operational-processes/gql/ReadPriceTagsFromDocument.graphql'),
          fetchPolicy: 'no-cache',
          variables: {
            input: {
              operation: 'revaluation',
              document_head: value[0],
              branch: this.currentBranch.id,
              isPrintAll: true,
              fix: true
            }
          }
        })
        this.printProducts = data?.ReadPriceTagsFromDocument?.map((el) => {
          return new ProductModel({ ...el?.Product, retailPrice: el?.value ?? el?.Product?.retailPrice })
        })
        if (this.printProducts?.length > 0) this.$bvModal.show('modal-print-tags')
      },
      formatPrice(summ = 0) {
        const val = summ === null ? 0 : summ
        const formatter = new Intl.NumberFormat('ru-RU', {
          style: 'decimal',
          currency: 'RUB',
          maximumFractionDigits: 2,
          minimumFractionDigits: 0
          // These options are needed to round to whole numbers if that's what you want.
          //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
          // maximumFractionDigits: 0 // (causes 2500.99 to be printed as $2,501)
        })

        return formatter.format(val)
      },
      updateDateRange() {},
      handlerSearch(search) {
        this.setPagination({
          ...this.getPagination,
          ['revaluations-docs']: {
            ...this.getPagination?.['revaluations-docs'],
            search
          }
        })
      },
      clearSearchStr() {
        this.setPagination({
          ...this.getPagination,
          ['revaluations-docs']: {
            ...this.getPagination?.['revaluations-docs'],
            search: ''
          }
        })
      },
      handlerPagination({ current_page = null, take = null }) {
        if (current_page) {
          this.setPagination({
            ...this.getPagination,
            ['revaluations-docs']: {
              ...this.getPagination?.['revaluations-docs'],
              skip: Number((current_page - 1) * take)
            }
          })
        }
        if (take) {
          this.setPagination({
            ...this.getPagination,
            ['revaluations-docs']: {
              ...this.getPagination?.['revaluations-docs'],
              take
            }
          })
        }
      },
      formatDate(date) {
        return new Intl.DateTimeFormat('ru-RU').format(new Date(date))
      },
      rowClicked({ id }) {
        this.$router.push({
          name: 'operation-process.overhead.revaluation.document.edit',
          params: { id }
        })
      },
      async removeHeads(items) {
        const { data } = await this.$apollo.mutate({
          mutation: require('../gql/RemoveHeads.graphql'),
          variables: {
            input: { ids: items }
          }
        })

        if (data?.RemoveRevaluationHeads?.status) {
          this.$apollo.queries.RevaluationHeads.refresh()
          this.selected = []
        }
      }
    },
    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch',
        getPagination: 'settings/getPagination'
      })
    }
  }
</script>

<style lang="scss" scoped>
  .table-substr {
    font-size: 12px;
    color: #888888;
  }

  #revaluation-list {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .b-table__wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .b-table__content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  :deep(.reval-table) th.cursor:not(:nth-child(1)):not(:nth-child(6)) {
    div {
      max-width: 100% !important;
      width: 100% !important;
    }
    .td-inner {
      span {
        width: 100%;
      }
      width: 100%;
      display: flex;
      justify-content: space-between !important;
    }
  }
  :deep(.reval-table) .badge {
    padding: 4px 12px;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    background: #efefef;
    color: #888888;

    &.success {
      background: #ebf9f5;
      color: #00cb91;
    }
  }

  :deep(.reval-table) td.text-nowrap {
    position: relative;
    .b-statuses {
      width: 2px !important;
      height: 32px !important;
      position: absolute;
      left: 0px;
      top: 50%;
      background: #bcbcbc;
      border-radius: 0px 1px 1px 0px;
      transform: translate(0%, -50%);

      &.success {
        background: #00cb91;
      }
    }
  }
  :deep(.reval-table) .revaluation-list {
    //min-height: calc(100%) !important;
    padding-bottom: 0 !important;
  }
  .table-inner {
    display: flex;
    flex: 1;
  }

  :deep(.reval-table) #table-docs {
    tr {
      cursor: pointer;
    }
  }

  :deep(.reval-table) th {
    padding: 10px 16px !important;
  }

  :deep(.reval-table) .revaluation-list {
    //max-height: 0 !important;
  }

  .bottom-descriprion {
    font-size: 12px;
    color: #888888;
  }
  @media (max-height: 700px) {
    :deep(.reval-table) .revaluation-list {
      max-height: 100% !important;
    }
  }
</style>
