<template>
  <div
    v-if="items.length > 0"
    class="b-table__navbar"
  >
    <img
      src="/img/icons/table/icon_checked.svg"
      alt=""
      class="cursor"
      @click="clear"
    />
    <span style="white-space: nowrap">{{ items.length }} {{ item_name }} </span>
    <div
      class="b-btns"
      style="margin-top: 0 !important"
    >
      <!--<b-button variant="dark" v-if="items.length === 1">
        Назначить главным группы
      </b-button>
      <b-button variant="dark">Поделиться</b-button>
      <b-button variant="dark">Экспорт</b-button>
      <b-button variant="dark">Печать</b-button>-->
      <slot>
        <template v-if="items_name === 'list_scales' && items.length === 1">
          <b-button
            variant="dark"
            @click="listScalesLoad"
          >
            {{ !items[0]?.active ? 'Загружать список' : 'Не загружать список' }}
          </b-button>
        </template>
        <template v-if="items_name === 'scales'">
          <b-button
            variant="dark"
            @click="scalesLoad"
          >
            Загрузить на весы
          </b-button>
        </template>
        <b-button
          v-if="!hide_remove"
          variant="dark"
          @click="removeItem"
        >
          Удалить
        </b-button>
        <b-button
          v-if="items_name === 'supplier' && items.length === 1"
          variant="dark"
          @click="openReconciliationActModal"
        >
          Акт сверки
        </b-button>
        <b-dropdown
          v-if="
            items_name === 'product' ||
            items_name === 'documents_product' ||
            (items_name === 'promotion' && items.length === 1) ||
            doc_type === 'incoming'
          "
          dropup
          no-caret
          class="navbar-dropdown"
          variant="dark"
        >
          <template #button-content> Печать </template>
          <b-dropdown-item
            v-if="doc_type === 'incoming'"
            @click="printPriceTags"
          >
            Печать УПД
          </b-dropdown-item>
          <b-dropdown-item @click="printPrice"> Печать ценников </b-dropdown-item>
          <b-dropdown-item @click="printLabels"> Печать этикеток </b-dropdown-item>
        </b-dropdown>
        <b-dropdown
          v-if="items_name === 'documents_reval'"
          dropup
          no-caret
          class="navbar-dropdown"
          variant="dark"
        >
          <template #button-content> Печать </template>
          <b-dropdown-item
            v-if="items.length === 1"
            @click="printDocumentTags"
          >
            Печать ценников
          </b-dropdown-item>
          <!--        <b-dropdown-item @click="printPriceTags"> Печать </b-dropdown-item>-->
        </b-dropdown>
        <b-button
          v-if="doc_type === 'incoming'"
          variant="dark"
          @click="importExel"
        >
          Выгрузка в Excel
        </b-button>
        <!--      <b-button-->
        <!--        variant="dark"-->
        <!--        v-if="items_name === 'product' || items_name === 'documents_product'"-->
        <!--        @click="printPriceTags"-->
        <!--      >-->
        <!--        Ценники-->
        <!--      </b-button>-->
        <!--      <b-button-->
        <!--        variant="dark"-->
        <!--        v-if="items_name === 'product' || items_name === 'documents_product'"-->
        <!--        @click="printLabels"-->
        <!--      >-->
        <!--        Этикетки-->
        <!--      </b-button>-->
        <template v-if="items_name === 'cash'">
          <b-button
            v-if="items.length === 1"
            variant="dark"
            @click="cashLoad"
          >
            Загрузить кассу
          </b-button>
          <b-button
            variant="dark"
            @click="cashDownload"
          >
            Выгрузить продажи
          </b-button>
        </template>
        <template v-if="doc_type === 'order'">
          <b-button
            variant="dark"
            @click="createIncoming"
          >
            Создать приход
          </b-button>
        </template>
        <!--      <template v-if="items_name === 'scales'">-->
        <!--        <b-button-->
        <!--          variant="dark"-->
        <!--          @click="scalesDrop"-->
        <!--        >-->
        <!--          Сбросить весы-->
        <!--        </b-button>-->
        <!--      </template>-->
        <!--      <template v-if="items_name === 'scales' && items.length === 1 && scale_active">-->
        <!--        <b-button-->
        <!--          variant="dark"-->
        <!--          @click="scalesDisable"-->
        <!--        >-->
        <!--          Отключить-->
        <!--        </b-button>-->
        <!--      </template>-->
        <!--      <template v-if="items_name === 'scales' && items.length === 1 && !scale_active">-->
        <!--        <b-button-->
        <!--          variant="dark"-->
        <!--          @click="scalesEnable"-->
        <!--        >-->
        <!--          Включить-->
        <!--        </b-button>-->
        <!--      </template>-->
        <b-button
          v-if="!hide_print"
          variant="dark"
          @click="printPriceTags"
        >
          Печать
        </b-button>
        <b-button
          v-if="cancel_ttn && items.length === 1"
          variant="dark"
          @click="cancelTtn"
        >
          ЕГАИС
        </b-button>
        <b-button
          v-if="items.length === 1 && doc_type === 'incoming'"
          variant="dark"
          @click="$emit('copyHead', items)"
        >
          Дублировать
        </b-button>
        <b-button
          v-if="doc_type === 'incoming' && items.length > 1"
          variant="dark"
          @click="linkIncoming"
        >
          Объединить
        </b-button>
        <template v-if="items_name === 'priceup' || items_name === 'document_product'">
          <b-button
            variant="dark"
            @click="PriceUp"
          >
            Наценить
          </b-button>
        </template>
        <template v-if="items_name === 'priceup'">
          <b-button
            variant="dark"
            @click="CostsAdd"
          >
            Назначить издержки
          </b-button>
        </template>
        <template v-if="items_name === 'product'">
          <b-dropdown
            dropup
            no-caret
            class="navbar-dropdown"
            variant="dark"
          >
            <template #button-content>Действия</template>
            <b-dropdown-item @click="Move"> Переместить</b-dropdown-item>
            <b-dropdown-item
              v-if="items_name === 'product' && items.length === 2"
              @click="mergeProducts"
              >Объединить</b-dropdown-item
            >
            <b-dropdown-item
              v-if="items.length === 1"
              @click="Copy"
            >
              Создать копию
            </b-dropdown-item>
          </b-dropdown>
          <b-dropdown
            dropup
            no-caret
            class="navbar-dropdown"
            variant="dark"
          >
            <template #button-content> Создать документ </template>
            <b-dropdown-item @click="revaluate('incoming')"> Документ прихода </b-dropdown-item>
            <b-dropdown-item @click="revaluate('write-off')"> Документ списания </b-dropdown-item>
            <b-dropdown-item @click="revaluate('return')"> Документ возврата </b-dropdown-item>
            <b-dropdown-item @click="revaluate('movement')"> Документ перемещения </b-dropdown-item>
            <b-dropdown-item @click="revaluate('inventory')"> Документ инвентаризации </b-dropdown-item>
            <b-dropdown-item @click="revaluate('sale')"> Документ продажи </b-dropdown-item>
            <b-dropdown-item @click="revaluate('revaluation')"> Документ переоценки </b-dropdown-item>
            <b-dropdown-item @click="revaluate('promo')"> Маркетинговую акцию </b-dropdown-item>
          </b-dropdown>
        </template>
        <template
          v-if="(['product', 'document_product', 'inventory'].includes(items_name) || movement) && items.length < 2"
        >
          <b-button
            variant="dark"
            @click="showMovement"
          >
            Показать товародвижение
          </b-button>
        </template>
        <template v-if="items_name === 'entity' && items.length === 1">
          <b-button
            variant="dark"
            @click="edit"
          >
            Редактировать
          </b-button>
        </template>
        <template v-if="items_name === 'invoice' && items.length === 1">
          <b-button
            variant="dark"
            @click="createSale"
          >
            Создать расход
          </b-button>
        </template>
        <template v-if="items_name === 'egais-product'">
          <b-button
            variant="dark"
            @click="write_off"
          >
            Списать
          </b-button>
        </template>
        <template v-if="items_name === 'egais-product' && items.length === 1">
          <b-button
            variant="dark"
            @click="link"
          >
            Связать
          </b-button>
        </template>
        <template>
          <b-button
            v-if="items_name === 'inventory' && items.length === 1"
            variant="dark"
            @click="editProduct"
          >
            Редактировать товар
          </b-button>
        </template>
        <template>
          <b-button
            v-if="export_to_bank"
            variant="dark"
            @click="export_to_bank"
          >
            Выгрузить в клиент-банк
          </b-button>
        </template>
      </slot>
    </div>
  </div>
</template>

<script>
  import { http } from '@/http/http'
  import { mapGetters, mapActions } from 'vuex'
  import { print_html } from '@/utils/print'

  export default {
    props: {
      items: {
        type: [Array, Object],
        default: () => []
      },
      items_name: {
        type: String,
        default: ''
      },
      doc_type: {
        type: String,
        default: ''
      },
      clear_selected: Function,
      export_to_bank: Function,
      hide_print: Boolean,
      active_scale: Boolean,
      hide_remove: Boolean,
      scale_active: Boolean,
      cancel_ttn: Boolean,
      price_mode: String,
      movement: {
        type: Boolean,
        default: false
      },
      all_items: Array
    },

    beforeDestroy() {
      this.clear()
    },

    computed: {
      ...mapGetters({
        product_items: 'products/getCurrentProducts'
      }),
      item_name() {
        if (this.items_name === 'supplier' || this.items_name === 'entity') {
          if (this.items.length === 1) return 'Контрагент'
          if (this.items.length > 1 && this.items.length < 5) return 'Контрагента'
          return 'Контрагентов'
        }

        if (this.items_name === 'printers') {
          if (this.items.length === 1) return 'Принтер'
          if (this.items.length > 1 && this.items.length < 5) return 'Принтера'
          return 'Принтеров'
        }

        if (['egais-product', 'product', 'priceup', 'document_product'].includes(this.items_name)) {
          if (this.items.length === 1) return 'Товар'
          if (this.items.length > 1 && this.items.length < 5) return 'Товара'
          return 'Товаров'
        }

        if (this.items_name === 'documents' || this.items_name === 'documents_reval') {
          if (this.items.length === 1) return 'Документ'
          if (this.items.length > 1 && this.items.length < 5) return 'Документа'
          return 'Документов'
        }

        if (this.items_name === 'invoice') {
          if (this.items.length === 1) return 'Документ'
          if (this.items.length > 1 && this.items.length < 5) return 'Документа'
          return 'Документов'
        }

        if (this.items_name === 'cash') {
          if (this.items.length === 1) return 'Позиция'
          if (this.items.length > 1 && this.items.length < 5) return 'Позиции'
          return 'Позиций'
        }
        return 'Товаров'
      }
    },

    methods: {
      ...mapActions({
        setPrintActions: 'products/setPrintActions'
      }),
      listScalesLoad() {
        this.$emit('listScalesLoad', this.items[0])
      },
      async importExel() {
        if (this.doc_type === 'incoming') {
          for (const id of this.items) {
            const { data } = await this.$apollo.query({
              query: require('../../../operational-processes/gql/IncomingHeadExel.graphql'),
              variables: {
                id
              }
            })
            if (data?.IncomingHeadExel) {
              const link = document.createElement('a')
              link.href = data?.IncomingHeadExel?.url
              link.download = 'exel'
              link.click()
              URL.revokeObjectURL(link.href)
            } else {
              this.$noty.error('Ошибка при загруке файла')
            }
          }
        }
      },

      mergeProducts() {
        this.$emit('merge_products', this.items)
      },

      printPrice() {
        this.setPrintActions({
          print: true
        })
        this.$emit('print_price_tags', this.items?.length ? this.items : this.product_items)
      },
      editProduct() {
        this.$emit('edit_product')
      },
      removeItem() {
        this.$emit('remove_items', this.items)
      },
      openReconciliationActModal() {
        this.$emit('open-reconciliation-act-modal', this.items[0])
      },
      edit() {
        this.$emit('edit', this.items[0])
      },
      cancelTtn() {
        this.$emit('cancelTtn')
      },
      linkIncoming() {
        this.$emit('linkIncoming')
      },
      cashLoad() {
        this.$emit('cash_load', this.items)
      },
      cashDownload() {
        this.$emit('cashDownload', this.items)
      },
      printDocumentTags() {
        this.setPrintActions({
          print: true
        })
        this.$emit('print_price_tags', this.items?.length ? this.items : this.product_items)
      },
      async printPriceTags() {
        if (this.doc_type === 'order') {
          this.$emit('print')
          return
        }
        if (this.items_name === 'promotion') {
          this.$emit('print')
          return
        }
        if (this.items_name === 'documents' || this.items_name === 'documents_reval') {
          for (let doc of this.items) {
            try {
              const { data } = await http.get(
                `/templates/${this.doc_type}/upd?document=${doc}&price_mode=${this.price_mode}`
              )
              if (data) {
                print_html(data)
                // newWin.close()
              } else {
                this.$noty.error('Ошибка печати')
              }
            } catch (e) {
              console.error(e)
              this.$noty.error('Ошибка печати')
            }
          }
          return
        } else if (this.items_name === 'invoice') {
          if (this.items.length === 1) {
            const arr = this.all_items.find((obj) => obj.id === this.items[0])
            if (arr.fixed) {
              for (let doc of this.items) {
                try {
                  const { data } = await http.get(`/templates/invoice?invoice=${doc}`)

                  if (data) {
                    print_html(data)
                  } else {
                    this.$noty.error('Ошибка печати')
                  }
                } catch (e) {
                  console.error(e)
                  this.$noty.error('Ошибка печати')
                }
              }
            } else {
              this.$noty.show('Нельзя напечатать не подтвержденный документ')
            }
          }
          return
        }
        this.setPrintActions({
          print: true
        })
        this.$emit('print_price_tags', this.items?.length ? this.items : this.product_items)
      },

      printLabels() {
        this.setPrintActions({
          print_label: true
        })
        this.$emit('print_price_tags', this.items)
      },

      PriceUp() {
        this.$bvModal.show('modal-price-up')
      },
      CostsAdd() {
        this.$bvModal.show('modal-costs-add')
      },
      Move() {
        this.$emit('product_move')
      },
      createSale() {
        this.$emit('create_sale', this.items[0])
      },
      Copy() {
        this.$emit('copy', this.items[0])
      },
      showMovement() {
        this.$emit('show_move', this.items?.length ? this.items[0] : this.product_items[0])
      },
      clear() {
        if (this.clear_selected) this.clear_selected()
      },
      revaluate(type) {
        this.$emit('revaluate', type)
      },
      write_off() {
        this.$emit('write_off')
      },
      link() {
        this.$emit('link')
      },
      scalesLoad() {
        this.$emit('scalesLoad')
      },
      createIncoming() {
        this.$emit('createIncoming')
      },
      scalesDrop() {
        this.$emit('scalesDrop')
      },
      scalesDisable() {
        this.$emit('disable')
      },
      scalesEnable() {
        this.$emit('enable')
      }
    }
  }
</script>

<style lang="scss" scoped>
  :deep(.navbar-dropdown) {
    .dropdown-menu.show {
      margin-bottom: 18px !important;
    }
  }
</style>
