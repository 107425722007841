import { BranchModel } from '@/models/branch.model'
import { OperationModel } from '@/models/operation.model'
import { UserModel } from '@/models/user.model'
import { RevaluationSpecPaginationModel } from '@/views/service/revaluation/models/revaluation-spec.model'
import { StorageModel } from '@/models/storage.model'
import { EntityModel } from '@/models/entity.model'

export class RevaluationHeadModel {
  constructor(request = {}) {
    this.id = request?.id
    this.branch = request?.branch ? new BranchModel(request?.branch) : null
    this.comment = request?.comment ?? ''
    this.storages = request?.storages ? request.storages.map((el) => new StorageModel(el)) : null
    this.entity = new EntityModel(request?.entity)
    this.date = request?.date ? new Date(request?.date) : new Date()
    this.fixed = request?.fixed
    this.number = request?.number
    this.operation = new OperationModel(request?.operation)
    this.owner = request?.owner ? new UserModel(request?.owner) : null
    this.sum = request?.sum
    this.new_sum = request?.new_sum
    this.specifications = new RevaluationSpecPaginationModel(request?.specifications)
  }

  setStorage = (val) => {
    this.storage = new StorageModel(val)
  }

  setEntity = (val) => {
    this.storage = new StorageModel(val)
  }

  setComment = (val) => {
    this.comment = val
  }

  get input() {
    return {
      id: this.id,
      comment: this.comment,
      entity: this.entity
    }
  }
}
