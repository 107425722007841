import { RevaluationHeadModel } from '@/views/service/revaluation/models/revaluation-head.model'
import { ProductModel } from '@/models/product.model'

export class RevaluationSpecModel {
  constructor(request = {}) {
    this.id = request?.id
    this.order = request?.order
    this.document_head = new RevaluationHeadModel(request?.document_head)
    this.product = new ProductModel(request?.product)
    this.price = request?.price
    this.retailPrice = request?.retailPrice
    this.new_retailPrice = request?.new_retailPrice
    this.delta = request?.delta
  }

  setMarkup = (val) => {
    this.new_retailPrice = Number((this.price * (1 + val / 100)).toFixed(2))
  }

  setNewPrice = (val) => {
    this.new_retailPrice = val
  }

  setDelta = (val) => {
    this.delta = val
  }

  get delta_price() {
    return isFinite(+(this.new_retailPrice - this.retailPrice).toFixed(2))
      ? +(this.new_retailPrice - this.retailPrice).toFixed(2)
      : 0
  }

  get delta_percent() {
    return Number((((this.new_retailPrice - this.retailPrice) / this.retailPrice) * 100).toFixed(2)) || 0
  }

  get delta_sum() {
    return (+(this.new_retailPrice - this.retailPrice).toFixed(2) || 0) * this.delta || 0
  }

  get markup() {
    return Number((((this.new_retailPrice - this.price) / this.purchasePrice) * 100).toFixed(4)) || 0
  }

  get new_sum() {
    return +(this.new_retailPrice * this.delta).toFixed(2) || 0
  }

  get purchase_sum() {
    return +(this.purchasePrice * this.delta).toFixed(2) || 0
  }

  get retail_sum() {
    return +(this.retailPrice * this.delta).toFixed(2) || 0
  }

  get purchasePrice() {
    return +this.price?.toFixed(2) || 0
  }

  get input() {
    return {
      id: this.id ?? null,
      product: this.product.id,
      new_retailPrice: +this.new_retailPrice,
      delta: +this.delta
    }
  }

  get remainders() {
    let rems = 0
    this.product.remainders?.forEach((el) => (rems += el.value))
    return rems
  }
}

export class RevaluationSpecPaginationModel {
  constructor(request = {}) {
    this.specification = request?.specification?.map((el) => new RevaluationSpecModel(el))
    this.total = request?.total
  }
}
