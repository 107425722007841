var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.items.length > 0 ? _c('div', {
    staticClass: "b-table__navbar"
  }, [_c('img', {
    staticClass: "cursor",
    attrs: {
      "src": "/img/icons/table/icon_checked.svg",
      "alt": ""
    },
    on: {
      "click": _vm.clear
    }
  }), _c('span', {
    staticStyle: {
      "white-space": "nowrap"
    }
  }, [_vm._v(_vm._s(_vm.items.length) + " " + _vm._s(_vm.item_name) + " ")]), _c('div', {
    staticClass: "b-btns",
    staticStyle: {
      "margin-top": "0 !important"
    }
  }, [_vm._t("default", function () {
    var _vm$items$;

    return [_vm.items_name === 'list_scales' && _vm.items.length === 1 ? [_c('b-button', {
      attrs: {
        "variant": "dark"
      },
      on: {
        "click": _vm.listScalesLoad
      }
    }, [_vm._v(" " + _vm._s(!((_vm$items$ = _vm.items[0]) !== null && _vm$items$ !== void 0 && _vm$items$.active) ? 'Загружать список' : 'Не загружать список') + " ")])] : _vm._e(), _vm.items_name === 'scales' ? [_c('b-button', {
      attrs: {
        "variant": "dark"
      },
      on: {
        "click": _vm.scalesLoad
      }
    }, [_vm._v(" Загрузить на весы ")])] : _vm._e(), !_vm.hide_remove ? _c('b-button', {
      attrs: {
        "variant": "dark"
      },
      on: {
        "click": _vm.removeItem
      }
    }, [_vm._v(" Удалить ")]) : _vm._e(), _vm.items_name === 'supplier' && _vm.items.length === 1 ? _c('b-button', {
      attrs: {
        "variant": "dark"
      },
      on: {
        "click": _vm.openReconciliationActModal
      }
    }, [_vm._v(" Акт сверки ")]) : _vm._e(), _vm.items_name === 'product' || _vm.items_name === 'documents_product' || _vm.items_name === 'promotion' && _vm.items.length === 1 || _vm.doc_type === 'incoming' ? _c('b-dropdown', {
      staticClass: "navbar-dropdown",
      attrs: {
        "dropup": "",
        "no-caret": "",
        "variant": "dark"
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function () {
          return [_vm._v(" Печать ")];
        },
        proxy: true
      }], null, false, 1300152060)
    }, [_vm.doc_type === 'incoming' ? _c('b-dropdown-item', {
      on: {
        "click": _vm.printPriceTags
      }
    }, [_vm._v(" Печать УПД ")]) : _vm._e(), _c('b-dropdown-item', {
      on: {
        "click": _vm.printPrice
      }
    }, [_vm._v(" Печать ценников ")]), _c('b-dropdown-item', {
      on: {
        "click": _vm.printLabels
      }
    }, [_vm._v(" Печать этикеток ")])], 1) : _vm._e(), _vm.items_name === 'documents_reval' ? _c('b-dropdown', {
      staticClass: "navbar-dropdown",
      attrs: {
        "dropup": "",
        "no-caret": "",
        "variant": "dark"
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function () {
          return [_vm._v(" Печать ")];
        },
        proxy: true
      }], null, false, 1300152060)
    }, [_vm.items.length === 1 ? _c('b-dropdown-item', {
      on: {
        "click": _vm.printDocumentTags
      }
    }, [_vm._v(" Печать ценников ")]) : _vm._e()], 1) : _vm._e(), _vm.doc_type === 'incoming' ? _c('b-button', {
      attrs: {
        "variant": "dark"
      },
      on: {
        "click": _vm.importExel
      }
    }, [_vm._v(" Выгрузка в Excel ")]) : _vm._e(), _vm.items_name === 'cash' ? [_vm.items.length === 1 ? _c('b-button', {
      attrs: {
        "variant": "dark"
      },
      on: {
        "click": _vm.cashLoad
      }
    }, [_vm._v(" Загрузить кассу ")]) : _vm._e(), _c('b-button', {
      attrs: {
        "variant": "dark"
      },
      on: {
        "click": _vm.cashDownload
      }
    }, [_vm._v(" Выгрузить продажи ")])] : _vm._e(), _vm.doc_type === 'order' ? [_c('b-button', {
      attrs: {
        "variant": "dark"
      },
      on: {
        "click": _vm.createIncoming
      }
    }, [_vm._v(" Создать приход ")])] : _vm._e(), !_vm.hide_print ? _c('b-button', {
      attrs: {
        "variant": "dark"
      },
      on: {
        "click": _vm.printPriceTags
      }
    }, [_vm._v(" Печать ")]) : _vm._e(), _vm.cancel_ttn && _vm.items.length === 1 ? _c('b-button', {
      attrs: {
        "variant": "dark"
      },
      on: {
        "click": _vm.cancelTtn
      }
    }, [_vm._v(" ЕГАИС ")]) : _vm._e(), _vm.items.length === 1 && _vm.doc_type === 'incoming' ? _c('b-button', {
      attrs: {
        "variant": "dark"
      },
      on: {
        "click": function ($event) {
          return _vm.$emit('copyHead', _vm.items);
        }
      }
    }, [_vm._v(" Дублировать ")]) : _vm._e(), _vm.doc_type === 'incoming' && _vm.items.length > 1 ? _c('b-button', {
      attrs: {
        "variant": "dark"
      },
      on: {
        "click": _vm.linkIncoming
      }
    }, [_vm._v(" Объединить ")]) : _vm._e(), _vm.items_name === 'priceup' || _vm.items_name === 'document_product' ? [_c('b-button', {
      attrs: {
        "variant": "dark"
      },
      on: {
        "click": _vm.PriceUp
      }
    }, [_vm._v(" Наценить ")])] : _vm._e(), _vm.items_name === 'priceup' ? [_c('b-button', {
      attrs: {
        "variant": "dark"
      },
      on: {
        "click": _vm.CostsAdd
      }
    }, [_vm._v(" Назначить издержки ")])] : _vm._e(), _vm.items_name === 'product' ? [_c('b-dropdown', {
      staticClass: "navbar-dropdown",
      attrs: {
        "dropup": "",
        "no-caret": "",
        "variant": "dark"
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function () {
          return [_vm._v("Действия")];
        },
        proxy: true
      }], null, false, 4277375889)
    }, [_c('b-dropdown-item', {
      on: {
        "click": _vm.Move
      }
    }, [_vm._v(" Переместить")]), _vm.items_name === 'product' && _vm.items.length === 2 ? _c('b-dropdown-item', {
      on: {
        "click": _vm.mergeProducts
      }
    }, [_vm._v("Объединить")]) : _vm._e(), _vm.items.length === 1 ? _c('b-dropdown-item', {
      on: {
        "click": _vm.Copy
      }
    }, [_vm._v(" Создать копию ")]) : _vm._e()], 1), _c('b-dropdown', {
      staticClass: "navbar-dropdown",
      attrs: {
        "dropup": "",
        "no-caret": "",
        "variant": "dark"
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function () {
          return [_vm._v(" Создать документ ")];
        },
        proxy: true
      }], null, false, 1135555592)
    }, [_c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.revaluate('incoming');
        }
      }
    }, [_vm._v(" Документ прихода ")]), _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.revaluate('write-off');
        }
      }
    }, [_vm._v(" Документ списания ")]), _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.revaluate('return');
        }
      }
    }, [_vm._v(" Документ возврата ")]), _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.revaluate('movement');
        }
      }
    }, [_vm._v(" Документ перемещения ")]), _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.revaluate('inventory');
        }
      }
    }, [_vm._v(" Документ инвентаризации ")]), _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.revaluate('sale');
        }
      }
    }, [_vm._v(" Документ продажи ")]), _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.revaluate('revaluation');
        }
      }
    }, [_vm._v(" Документ переоценки ")]), _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.revaluate('promo');
        }
      }
    }, [_vm._v(" Маркетинговую акцию ")])], 1)] : _vm._e(), (['product', 'document_product', 'inventory'].includes(_vm.items_name) || _vm.movement) && _vm.items.length < 2 ? [_c('b-button', {
      attrs: {
        "variant": "dark"
      },
      on: {
        "click": _vm.showMovement
      }
    }, [_vm._v(" Показать товародвижение ")])] : _vm._e(), _vm.items_name === 'entity' && _vm.items.length === 1 ? [_c('b-button', {
      attrs: {
        "variant": "dark"
      },
      on: {
        "click": _vm.edit
      }
    }, [_vm._v(" Редактировать ")])] : _vm._e(), _vm.items_name === 'invoice' && _vm.items.length === 1 ? [_c('b-button', {
      attrs: {
        "variant": "dark"
      },
      on: {
        "click": _vm.createSale
      }
    }, [_vm._v(" Создать расход ")])] : _vm._e(), _vm.items_name === 'egais-product' ? [_c('b-button', {
      attrs: {
        "variant": "dark"
      },
      on: {
        "click": _vm.write_off
      }
    }, [_vm._v(" Списать ")])] : _vm._e(), _vm.items_name === 'egais-product' && _vm.items.length === 1 ? [_c('b-button', {
      attrs: {
        "variant": "dark"
      },
      on: {
        "click": _vm.link
      }
    }, [_vm._v(" Связать ")])] : _vm._e(), [_vm.items_name === 'inventory' && _vm.items.length === 1 ? _c('b-button', {
      attrs: {
        "variant": "dark"
      },
      on: {
        "click": _vm.editProduct
      }
    }, [_vm._v(" Редактировать товар ")]) : _vm._e()], [_vm.export_to_bank ? _c('b-button', {
      attrs: {
        "variant": "dark"
      },
      on: {
        "click": _vm.export_to_bank
      }
    }, [_vm._v(" Выгрузить в клиент-банк ")]) : _vm._e()]];
  })], 2)]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }